import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import {
  FinalAnswerType,
  PostType,
  QuestionCloseFormType,
  QuestionDetailDefault,
  QuestionDetailType,
  QuestionFormType,
  QuestionType,
} from "@/types/question/question-type";
import { SearchedQuestionType } from "@/types/search";
import { AxiosError } from "axios";
import { getHeaderAuth, isLoggedIn } from "../handlers-helpers";

export async function questionListHandler(course_id: number): Promise<void | Array<QuestionType>> {
  return await request(undefined, "GET", `question/course/${course_id}`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });
}

export async function questionPostHandler(payload: QuestionFormType, course_id: number): Promise<void> {
  return await request(undefined, "POST", `question/course/${course_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function questionDetailHandler(question_id: number): Promise<QuestionDetailType> {
  return await request(undefined, "GET", `question/${question_id}`, undefined, undefined, isLoggedIn() ? getHeaderAuth() : undefined)
    .then((response) => {
      if (response === undefined) {
        showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
        return QuestionDetailDefault;
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });
}

export async function questionCloseHandler(payload: QuestionCloseFormType, question_id: number): Promise<void | QuestionDetailType> {
  return await request(undefined, "POST", `question/${question_id}/close`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function questionAnswerHandler(payload: PostType | FinalAnswerType, question_id: number): Promise<void> {
  return await request(undefined, "POST", `answer/${question_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function questionFinalHandler(payload: PostType, question_id: number): Promise<void> {
  return await request(undefined, "POST", `answer/${question_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function questionCommentHandler(payload: PostType, question_id: number): Promise<void> {
  return await request(undefined, "POST", `comment/${question_id}`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function questionUpdateHandler(payload: PostType, question_id: number): Promise<void | QuestionDetailType> {
  return await request(undefined, "PATCH", `comment/${question_id}`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function searchHandler(expression: string): Promise<Array<SearchedQuestionType> | void> {
  return await request(undefined, "POST", `search`, undefined, { search: expression }, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });
}
