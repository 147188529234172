import { AnswerEnum } from "../enum/answer-enum";
import { EnrollmentEnum } from "../enum/enrollment-enum";
import { CategoryType } from "../helpers/category-type";

export interface QuestionType {
  _id: number;
  title: string;
  user: UserType;
  category: Array<CategoryType>;
  date: Date;
  closed?: boolean;
  preview: {
    user: UserType;
    post: string;
  };
}

export interface QuestionFormType {
  title: string;
  category: Array<number>;
  post: string;
}

export const QuestionFormDefault: QuestionFormType = {
  title: "",
  category: [],
  post: "",
};

export interface UserCommentType {
  _id: number;
  user: UserType;
  date: Date;
  post: string;
  comments?: Array<UserCommentType>;
  votes: number;
  my_vote: boolean;
  status?: AnswerEnum;
}

export interface QuestionDetailType {
  _id: number;
  title: string;
  user: UserType;
  category: Array<CategoryType>;
  date: Date;
  closed?: boolean;
  post: string;
  answers: Array<UserCommentType>;
  final_answer?: UserCommentType;
  enrollment_status?: EnrollmentEnum;
  teacher_id: number;
}

export const QuestionDetailDefault: QuestionDetailType = {
  _id: -1,
  title: "",
  user: {
    name: "",
    image: "",
    role: "",
  },
  category: [],
  date: new Date(),
  closed: false,
  post: "",
  answers: [],
  teacher_id: -1,
};

export interface QuestionCloseFormType {
  correct_answers: Array<number>; // id_answer
  incorrect_answers: Array<number>; // id_answer
  post: string;
  award: Array<{ id_answer: number; bonus_points: number }>;
}

export interface PostType {
  post: string;
}

export const PostForm: PostType = {
  post: "",
};

export interface UserType {
  name: string;
  role: string;
  image: string;
}

export interface FinalAnswerType {
  award: Array<AnswerAwardType>;
  post: string;
}

export const FinalAnswerDefault: FinalAnswerType = {
  award: [],
  post: "",
};

export interface AnswerAwardType {
  id_answer: number;
  bonus_points: number;
}
