
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { getBetterContrast } from "../helpers/colours";
import { CategoryType } from "../types/helpers/category-type";

export default class Category extends Vue {
  @Prop() readonly category!: CategoryType;

  decideFontColour(input: string): string {
    return getBetterContrast(input);
  }
}
