import store from "@/store";
import { AxiosRequestHeaders } from "axios";

export function prepareUserId(): string {
  return store.getters.getAccessToken;
}

export function isLoggedIn(): boolean {
  return store.getters.isLoggedIn;
}
export function getHeaderAuth(): Array<AxiosRequestHeaders> {
  return [
    {
      Authorization: "Bearer " + prepareUserId(),
    },
  ];
}
