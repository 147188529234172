interface rgbColor {
  r: number;
  g: number;
  b: number;
}

export function getBetterContrast(input: string): string {
  const color = stringToColor(input);
  if (rgbToLuminance(color) < 127) return "white";
  return "black";
}

export function stringToColor(input: string): rgbColor {
  const r = parseInt(input.substring(1, 3), 16);
  const g = parseInt(input.substring(3, 5), 16);
  const b = parseInt(input.substring(5, 7), 16);

  return { r, g, b };
}

export function rgbToLuminance(input: rgbColor): number {
  return input.r * 0.2126 + input.g * 0.7152 + input.b * 0.0722;
}
